import React from 'react';
import { graphql, useStaticQuery} from 'gatsby';
import { Helmet, } from 'react-helmet-async';
import { HelmetProvider } from 'react-helmet-async';

export default function SEO({ title, description, image,url }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          url
          title
          description
          image
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  const seo = {
    title: title || defaults.title,
    description: description || defaults.description,
    image: image ? `${image}` : defaults.image,
    url: url || defaults.url,
  };

  /*const keywords = site.siteMetadata.keywords
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title*/


  return (
    /*<Helmet title={seo.title}>
      <html lang="en" />
      <meta
    property="og:title"
      content={seo.title}
  />
  <meta name="type" property="og:type" content="article"/>
  <meta name="url" property="og:url" content={seo.url}/>
      <meta name="description" property="og:description" content={seo.description} />
      <meta name="image" property="og:image:url" content={seo.image} />
    </Helmet>
*/
<HelmetProvider>
<Helmet defer={false}>
  <title>{seo.title}</title>
  <meta
  name="title"
    property="og:title"
      content={seo.title}
  />
  <meta name="type" property="og:type" content="article"/>
  <meta name="url" property="og:url" content={seo.url}/>
      <meta name="description" property="og:description" content={seo.description} />
      <meta name="image" property="og:image:url" content={seo.image} />
</Helmet>
</HelmetProvider>
);
}