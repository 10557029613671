import * as React from "react"
import  Header  from "../layout/header"
import '../index.scss';
//import './sites/all/themes/tickets/js/estacas/form_tickets_rd.js';
import '../sites/all/themes/tickets/js/estacas/map.js';
import '../sites/all/themes/tickets/js/estacas/cards_rd'
//import './sites/all/themes/tickets/js/estacas/stickyfloat'
import '../sites/all/themes/tickets/js/estacas/eventos'
//import '../sites/all/themes/tickets/js/estacas/form_tickets_rd'
//import './stylesheets/tickets.css'
//import './sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/estacas/instagram_posts';
import '../sites/all/themes/tickets/js/estacas/header_rd';
import '../sites/all/themes/tickets/js/_analyticstagmanager';
import SEO from '../components/seo'

/*
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-MBGJKRV',
    dataLayer: {
        userId: '001',
        userProject: 'project'
    }
}
 
TagManager.initialize(tagManagerArgs)
*/
//ReactGA.initialize(app.idGA4);
//ReactGA.send("pageview");


// Send a custom event
/*ReactGA.event("send",{
  category: "prueba",
  action: "pageView",
  label: "etiqueta", // optional
  value: 99, 
});*/
const IndexPage = () => {
  return (
    
    <body name="google" content="notranslate" className={'html front not-logged-in no-sidebars page-node page-node- page-node-1612 node-type-page lang-es'}>
     <SEO />
    <Header />
    </body>
  )
}

export default IndexPage
